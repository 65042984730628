import { Box, SvgIcon, Typography, TypographyProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

interface Props extends TypographyProps {
  svgIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined
  showIcon?: boolean
  svgClassName?: string
  boxClassName?: string
}

const TextComponent: React.FC<Props> = (props: Props) => {
  const { children, className, showIcon, svgIcon, svgClassName, boxClassName, ...rest } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {(!showIcon && !svgIcon) && (
        <Box className={clsx(classes.root, boxClassName)}>
          <Typography {...rest} className={className}>
            {children}
          </Typography>
        </Box>
      )}
      {(showIcon && svgIcon) && (
        <Box className={clsx(classes.root, boxClassName)}>
          <Typography {...rest} className={className}>
            {children}
          </Typography>
          <SvgIcon
            component={svgIcon}
            className={svgClassName}
            viewBox="inherit"
            classes={{
              root: classes.svgIcon,
            }}
          />
        </Box>
      )}
    </React.Fragment>

  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75px',
  },
  svgIcon: {
    width: 'inherit',
    height: 'inherit',
  },
}))

export default TextComponent;
