import { Box, Divider, makeStyles, Paper, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CarbonSDK } from 'carbon-js-sdk'
import { QueryAccountDataResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/query'
import clsx from 'clsx'
import Long from 'long'
import { default as React, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'


import { DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { TextComponent } from 'js/components/Text'
import { TaskNames } from 'js/constants'
import { truncateStr } from 'js/helpers'
import { useTaskSubscriber } from 'js/hooks'
import { RootState } from 'js/store'
import { switcheo } from 'js/theme/palettes/colors'
import { BN_ONE, BN_ZERO, bnOrZero } from 'js/utils'
import { getUserCDPBalance } from 'js/views/Cdp/helper'

import { UserCDPBalance } from '../Account'
import { calculateInterestForTimePeriod, getTokenAprArr, getTotalCdpSharesUSD } from './cdpUtility'

interface userCDPBalanceWithBorrows extends UserCDPBalance {
  borrow: BigNumber
  price: BigNumber
  symbol: string | undefined,
  borrowAPY: BigNumber,
  lentAPY: BigNumber,
  borrowRewardAPY: BigNumber,
  lentRewardAPY: BigNumber,
  totalProjectedProfit: BigNumber,
  totalProjectedRewardProfit: BigNumber,
  totalCap: BigNumber
}

interface Total {
  totalSuppliedUSD: BigNumber,
  totalCollateralUSD: BigNumber,
  totalBorrowedUSD: BigNumber,
  totalLentUSD: BigNumber
}

interface Props {
  address: string
}

interface userCDPBalanceWithHF extends QueryAccountDataResponse {
  healthFactor: string
}
const CDP: React.FunctionComponent<Props> = ({
  address,
}: Props): ReactElement<Props> | null => {
  const balance = useSelector((state: RootState) => state.account.balance)
  const [balanceLoading] = useTaskSubscriber(TaskNames.Account.Holdings)
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const classes = useStyles()
  const [userBalance, setUserBalance] = useState<userCDPBalanceWithBorrows[]>([])
  const [userCDPData, setUserCDPData] = useState<userCDPBalanceWithHF | undefined>(undefined)
  const [total, setTotal] = useState<Total>({
    totalSuppliedUSD: BN_ZERO,
    totalCollateralUSD: BN_ZERO,
    totalBorrowedUSD: BN_ZERO,
    totalLentUSD: BN_ZERO
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState<boolean>(true)
  const itemsPerPage = 10

  const allCIBTTokens = useMemo(() => Object.values(balance).filter((balance) => CarbonSDK.TokenClient.isCdpToken(balance.denom)), [balance])
  // get CDP
  const [userCDPBalance, setUserCDPBalance] = useState<UserCDPBalance[]>([])
  const [loadingCdp, setLoadingCdp] = useState(false)
  useEffect(() => {
    if (!address || !sdk || loadingCdp || balanceLoading) return
    const processCDP = async () => {
      setLoadingCdp(true)
      const userCDPBalanceResult = await getUserCDPBalance(allCIBTTokens, address, sdk)
      setLoadingCdp(false)
      setUserCDPBalance(userCDPBalanceResult)
    }
    processCDP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, sdk, allCIBTTokens, balanceLoading])

  useEffect(() => {
    if (!sdk) return
    const getUserDebt = async () => {
      setLoading(true)
      const allCDPAssets = await sdk.query.cdp.AssetAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      const userDebts = (await sdk.query.cdp.AccountDebtAll({ address }))?.debts ?? []
      const userBalanceWithDebt: userCDPBalanceWithBorrows[] = []
      for (const userDebt of userDebts) {
        const isInUserBalance = userCDPBalance.find((o) => o.underlyingDenom === userDebt.denom)
        if (!isInUserBalance && userDebt.denom !== 'usc') {
          userCDPBalance.push({
            denom: `cibt/${userDebt.denom}`,
            available: "0",
            order: "0",
            position: "0",
            underlying: BN_ZERO,
            underlyingDenom: userDebt.denom,
            collateralized: BN_ZERO
          })
        }
      }
      const rewardSchemes = await (await sdk.query.cdp.RewardSchemesAll({})).rewardSchemes
      const tokenPrices = await sdk.token.usdValues
      const tokens = await sdk.token.tokens
      const userCDPData = await sdk.query.cdp.AccountData({ address }) as userCDPBalanceWithHF
      const debtInfosAll = await (await sdk.query.cdp.TokenDebtAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      }))?.debtInfosAll
      const params = await (await sdk.query.cdp.Params({}))?.params
      const totalSupplyOfAllToken = await (await sdk.query.bank.TotalSupply({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      }))?.supply
      const cdpModAddress = sdk.cdp.getCdpModuleAddress()
      const cdpModBalances = await (await sdk.query.bank.AllBalances({ address: cdpModAddress, resolveDenom: false }))?.balances
      let userTotalMintDebt = BN_ZERO
      const userMintDebt = await sdk.query.cdp.AccountStablecoin({ address })
      userTotalMintDebt = bnOrZero(userMintDebt?.principal).plus(bnOrZero(userMintDebt?.interest))
      if (!userCDPBalance.find((o) => o.underlyingDenom === 'usc')) {
        userCDPBalance.push({
          denom: `usc`,
          available: "0",
          order: "0",
          position: "0",
          underlying: BN_ZERO,
          underlyingDenom: 'usc',
          collateralized: BN_ZERO
        })
      }

      const userEmodeCat = (await sdk.query.cdp.AccountEMode({ address })).eModeCategoryName
      const emodeCategory = (await sdk.query.cdp.EModeAll({})).eModeCategories
      let liquidationThreshold = BN_ZERO
      let totalDebtUSD = BN_ZERO

      for (const userBalance of userCDPBalance) {
        const denom = userBalance?.underlyingDenom
        const decimals = sdk?.token.getDecimals(denom ?? '') ?? 6
        const debtBalInfo = userDebts.find((o) => o.denom === denom)
        let price = denom === 'usc' ? BN_ONE : bnOrZero(sdk.token.getUSDValue(denom))
        if (price.eq(0)) {
          await sdk.token.reloadUSDValues([denom])
          price = bnOrZero(sdk.token.getUSDValue(denom))
        }
        const debtInfo = debtInfosAll.find((o) => o.denom === denom)
        const borrowAPY = denom === 'usc' ? bnOrZero(0.005) : (await sdk.cdp.calculateAPY(denom, debtInfo))
        const lentAPY = denom === 'usc' ? bnOrZero(0) : (await sdk.cdp.calculateLendAPY(denom, borrowAPY, debtInfo, params))
        const interest = calculateInterestForTimePeriod(0, debtInfo, borrowAPY)
        const currentCIM = bnOrZero(debtInfo?.cumulativeInterestMultiplier).times(interest.plus(1))
        const targetCIM = bnOrZero(debtBalInfo?.initialCumulativeInterestMultiplier).shiftedBy(0)
        const accountInterestMultiplier = BigNumber.max(BN_ONE, currentCIM.div(targetCIM))
        let debt = bnOrZero(debtBalInfo?.principal ?? 0).multipliedBy(accountInterestMultiplier.isFinite() ? accountInterestMultiplier : 0).shiftedBy(-(decimals ?? 6))
        debt = denom === 'usc' ? debt.plus(userTotalMintDebt.shiftedBy(-decimals)) : debt
        const symbol = sdk.token.getSymbol(denom)
        const totalSupplyBN = bnOrZero(totalSupplyOfAllToken.find((o) => o.denom === userBalance?.denom)?.amount)
        const cdpModBalance = bnOrZero(cdpModBalances.find((o) => o.denom === denom)?.amount)
        const totalCdpSharesUSD = getTotalCdpSharesUSD(debtInfo, params, borrowAPY, decimals, totalSupplyBN, cdpModBalance, price)
        const totalBorrowedUSD = (bnOrZero(debtInfo?.totalPrincipal ?? 0)).multipliedBy(price).shiftedBy(-Number(decimals))
        const { overallRewardApr: overallSupplyRewardApr } = await getTokenAprArr(rewardSchemes, tokenPrices, tokens, "lend", denom, totalCdpSharesUSD)
        const { overallRewardApr } = await getTokenAprArr(rewardSchemes, tokenPrices, tokens, "borrow", denom, totalBorrowedUSD)
        const userTotalLentUSD = (userBalance.underlying.plus(userBalance.collateralized)).multipliedBy(price)
        const userTotalBorrowedUSD = debt.multipliedBy(price)
        totalDebtUSD = totalDebtUSD.plus(userTotalBorrowedUSD)

        // base APY
        const netLentAPY = (lentAPY.plus(0))
        const netBorrowAPY = ((borrowAPY.negated().plus(0)))
        const lentAPYProfit = netLentAPY.multipliedBy(userTotalLentUSD)
        const borrowAPYProfit = netBorrowAPY.multipliedBy(userTotalBorrowedUSD)
        const totalProfit = BigNumber.sum(lentAPYProfit, borrowAPYProfit)
        const totalCap = userTotalLentUSD

        // Reward APY
        const netLentRewardAPY = overallSupplyRewardApr
        const netBorrowRewardAPY = overallRewardApr
        const lentAPYRewardProfit = netLentRewardAPY.multipliedBy(userTotalLentUSD)
        const borrowAPYRewardProfit = netBorrowRewardAPY.multipliedBy(userTotalBorrowedUSD)
        const totalRewardProfit = BigNumber.sum(lentAPYRewardProfit, borrowAPYRewardProfit)
        userBalanceWithDebt.push({
          ...userBalance,
          borrow: debt,
          price: price,
          symbol,
          borrowAPY: borrowAPY?.shiftedBy(2),
          lentAPY: lentAPY?.shiftedBy(2),
          borrowRewardAPY: overallRewardApr.shiftedBy(2),
          lentRewardAPY: overallSupplyRewardApr.shiftedBy(2),
          totalProjectedProfit: totalProfit,
          totalProjectedRewardProfit: totalRewardProfit,
          totalCap
        })

        //Cal health factor
        const assetInfo = allCDPAssets.assetParamsAll?.find((o) => o.assetParams?.denom === denom)
        const userEmodeCatInfo = emodeCategory.find((o) => o.name === userEmodeCat)
        const isEModeDenom = !userEmodeCatInfo ? false : userEmodeCatInfo.denoms.includes(denom)
        if (userBalance.collateralized.gt(0)) {
          if (!assetInfo?.assetParams?.liquidationThreshold) {
            console.warn('cdp liquidation threshold not found for', denom)
          } else {
            const liqThreshold = (emodeCategory && isEModeDenom && userEmodeCatInfo) ? userEmodeCatInfo.liquidationThreshold : assetInfo.assetParams?.liquidationThreshold
            const assetLiquidationThreshold = userTotalLentUSD.times(bnOrZero(liqThreshold).shiftedBy(-4))
            liquidationThreshold = liquidationThreshold.plus(assetLiquidationThreshold)
          }
        }
      }
      userCDPData.healthFactor = (totalDebtUSD.isZero() ? BigNumber(Infinity) : liquidationThreshold.div(totalDebtUSD)).toFixed(2)
      setUserBalance(userBalanceWithDebt)
      setUserCDPData(userCDPData)
    }
    getUserDebt()
  }, [address, sdk, userCDPBalance])


  useEffect(() => {
    let totalSuppliedUSD = BN_ZERO
    let totalLentUSD = BN_ZERO
    let totalCollateralUSD = BN_ZERO
    let totalBorrowedUSD = BN_ZERO
    userBalance.forEach((o) => {
      totalCollateralUSD = totalCollateralUSD.plus((o.collateralized.multipliedBy(o.price)))
      totalLentUSD = totalLentUSD.plus((o.underlying.multipliedBy(o.price)))
      totalBorrowedUSD = totalBorrowedUSD.plus((o.borrow.multipliedBy(o.price)))
    })
    totalSuppliedUSD = totalCollateralUSD.plus(totalLentUSD)
    totalLentUSD = totalLentUSD.plus(totalCollateralUSD)
    setTotal({
      totalSuppliedUSD,
      totalCollateralUSD,
      totalBorrowedUSD,
      totalLentUSD
    })
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBalance])

  const healthFactor = bnOrZero(userCDPData?.healthFactor)
  let healthColor = healthFactor.gte(3) ? "green" : healthFactor.gt(1) ? "orange" : "red"

  const totalProjectedBaseProfitOfAll = userBalance.reduce((prev, curr) => prev.plus(curr.totalProjectedProfit), BN_ZERO)
  const totalProjectedRewardProfitOfAll = userBalance.reduce((prev, curr) => prev.plus(curr.totalProjectedRewardProfit), BN_ZERO)
  const totalProjectedProfitOfAll = totalProjectedBaseProfitOfAll.plus(totalProjectedRewardProfitOfAll)
  const totalCapOfAll = userBalance.reduce((prev, curr) => prev.plus(curr.totalCap), BN_ZERO)
  const overallNetAPY = totalProjectedProfitOfAll.div(totalCapOfAll).shiftedBy(2)
  const baseAPY = totalProjectedBaseProfitOfAll.div(totalCapOfAll).shiftedBy(2)
  const rewardAPY = totalProjectedRewardProfitOfAll.div(totalCapOfAll).shiftedBy(2)
  return (
    <Section title={''}>
      <DataLoadSegment loading={loading || balanceLoading || loadingCdp}>
        <div>
          <div id="health-and-apy-wrapper" className={classes.healthapyWrapper}>
            <Paper className={classes.infoWrapper}>
              <div>
                <Typography variant="h3">
                  Health Factor
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <div>
                <Typography variant="h1" className={clsx(classes.healthFactor, healthColor)}>
                  {healthFactor.gte(10) ? '> 10.00' : healthFactor.toFixed(2)}
                </Typography>
              </div>
            </Paper>
            <Paper className={classes.infoWrapper}>
              <div className={classes.netAPYWrapper}>
                <div id="overall" style={{ gridArea: 'overallApy' }}>
                  <div>
                    <Typography variant="h3">
                      Net APY <Typography variant="h4" component="span" style={{ verticalAlign: "middle" }}>( Base + Reward )</Typography>
                    </Typography>
                  </div>
                  <Divider className={classes.divider} />
                  <div>
                    <Typography variant="h1">
                      {overallNetAPY.isNaN() ? 0 : overallNetAPY?.toFixed(2)}% <Typography variant="h3" component="span" align="center" style={{ verticalAlign: 'middle' }}>( {baseAPY.isNaN() ? 0 : baseAPY?.toFixed(2)}% + {rewardAPY.isNaN() ? 0 : rewardAPY?.toFixed(2)}% )</Typography>
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
          <div id="additional-info-wrapper" className={classes.additionalInfoWrapper}>
            <Paper className={clsx(classes.infoWrapper)}>
              <div>
                <Typography variant="h3">
                  Total Supplied
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <div>
                <Typography variant="h1">
                  ${total.totalSuppliedUSD.toNumber().toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </Typography>
              </div>
            </Paper>
            <Paper className={clsx(classes.infoWrapper)}>
              <div>
                <Typography variant="h3">
                  Total Collateralized
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <div>
                <Typography variant="h1">
                  ${total.totalCollateralUSD.toNumber().toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </Typography>
              </div>
            </Paper>
            <Paper className={clsx(classes.infoWrapper)}>
              <div>
                <Typography variant="h3">
                  Total Borrowed
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <div>
                <Typography variant="h1">
                  ${total.totalBorrowedUSD.toNumber().toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </Typography>
              </div>
            </Paper>
          </div>
        </div>
        <ListTable className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Asset</TableCell>
              <TableCell>Supplied</TableCell>
              <TableCell>Collateralized</TableCell>
              <TableCell>Borrowed</TableCell>
              <TableCell>Base APY (Lent)</TableCell>
              <TableCell>Reward APY (Lent)</TableCell>
              <TableCell>Base APY (Borrow)</TableCell>
              <TableCell align="left">Reward APY (Borrow)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.rowInfoTable}>
            {userBalance.map(renderReward)}
          </TableBody>
        </ListTable>
        {userBalance.length > 0 && (
          <Box marginTop={3}>
            <PaginationByData
              data={userBalance}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {!userBalance.length && (
          <TableEmptyState itemName="CDP" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderReward(balance: userCDPBalanceWithBorrows, index: number): ReactNode {
    return (
      <TableRow key={index.toString()} hover>
        <TableCell>
          <TextComponent
            showIcon={false}
            svgIcon={undefined}
          >
            {balance.symbol ?? truncateStr(balance.underlyingDenom, 5, 4, "...")}
          </TextComponent>
        </TableCell>
        <TableCell>
          {balance.underlying.plus(balance.collateralized).toNumber()}
        </TableCell>
        <TableCell>
          {balance.collateralized.toNumber()}
        </TableCell>
        <TableCell>
          {balance.borrow.toNumber()}
        </TableCell>
        <TableCell>
          {balance.lentAPY.toFixed(2)}%
        </TableCell>
        <TableCell>
          {balance.lentRewardAPY.toFixed(2)}%
        </TableCell>
        <TableCell>
          {balance.borrowAPY.toFixed(2)}%
        </TableCell>
        <TableCell>
          {balance.borrowRewardAPY.toFixed(2)}%
        </TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  netAPYWrapper: {
  },
  healthFactor: {
    '&.green': {
      color: switcheo.green[400]
    },
    '&.orange': {
      color: switcheo.warning.primary
    },
    '&.red': {
      color: switcheo.loss
    }
  },
  rowInfoTable: {
    '& > thead > tr > .MuiTableCell-head': {
      padding: theme.spacing(1)
    }
  },
  table: {
    marginTop: theme.spacing(4),
    '& > thead > tr > .MuiTableCell-head': {
      padding: theme.spacing(1)
    }
  },
  divider: {
    margin: theme.spacing(1, 0, 0.5, 0)
  },
  additionalInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  healthapyWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  infoWrapper: {
    border: '1px solid lightgrey',
    padding: theme.spacing(1, 2),
    '&.noBorderRadius': {
      borderRadius: 0
    },
    '&.fullWidth': {
      gridColumn: '1/-1',
      border: 0,
      color: '#FFFFFF'
    }
  },
  totalSupplied: {
    gridColumn: '1/-1',
    color: '#FFFFFF'
  }
}))

export default CDP
