import { reverseHex } from '@cityofzion/neon-core/lib/u'
import { Box, makeStyles, TableRow, Theme, Tooltip, Typography } from '@material-ui/core'
import { toBech32Address } from '@zilliqa-js/crypto'
import { BlockchainUtils, CarbonSDK } from 'carbon-js-sdk'
import React, { ReactElement, useMemo } from 'react'


import { BlockchainIcon, CellLink, TableCellInfo, TableSectionCell } from 'js/components'
import { TextComponent } from 'js/components/Text'
import { BlockChainPaths } from 'js/constants'
import { useRedux } from 'js/hooks'
import { bnOrZero, reverseStr } from 'js/utils'

import { Assets } from '../helper'

interface Props {
  model: Assets
}

const TokenInfoRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props
  const sdk = useRedux((state) => state.core.carbonSDK)
  const classes = useStyles()

  const { lendApr, borrowApr, totalAmount, availableBorrow } = model
  const lendAprBN = bnOrZero(lendApr);
  const borrowAprBN = bnOrZero(borrowApr);
  const totalAmountBN = bnOrZero(totalAmount).shiftedBy(-model.decimals);

  const { tokenSymbol, tokenDesc, blockchain } = useMemo(() => {
    return {
      tokenSymbol: sdk?.token.getSymbol(model.denom),
      tokenDesc: sdk?.token.getTokenDesc(model.denom),
      blockchain: sdk?.token.getBlockchainV2(model.denom),
    }
  }, [model, sdk])

  const getAssetIDLink = () => {
    const paths = sdk?.network === CarbonSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet
    switch (blockchain) {
      case BlockchainUtils.Blockchain.Ibc: {
        return paths.ibc.replace(':path', `/tokens/details?token=${model?.tokenAddress}`)
      }
      case BlockchainUtils.Blockchain.Ethereum: {
        if (model?.denom === 'eth1') {
          return paths.eth.replace(':path', '/')
        }
        return paths.eth.replace(':path', `/token/0x${model?.tokenAddress}`)
      }
      case BlockchainUtils.Blockchain.Zilliqa: {
        if (model?.denom === 'zil1' || model?.tokenAddress === '0000000000000000000000000000000000000000') {
          return paths.zil.replace(':path', '/')
        }
        return paths.zil.replace(':path', `/address/${toBech32Address(model?.tokenAddress)}`)
      }
      case BlockchainUtils.Blockchain.BinanceSmartChain: {
        if (model?.denom === 'bnb1') {
          return paths.bsc.replace(':path', '/')
        }
        return paths.bsc.replace(':path', `/token/0x${model?.tokenAddress}`)
      }
      case BlockchainUtils.Blockchain.Neo3: {
        return paths.neo3.replace(':path', `/tokens/nep17/0x${reverseHex(model?.tokenAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo: {
        if (model?.denom === 'nneo1') {
          return paths.neo.replace(':path', `/nep5/${model?.tokenAddress}/page/1`)
        }
        return paths.neo.replace(':path', `/nep5/${reverseStr(model?.tokenAddress, 2)}/page/1`)
      }
      default:
        return ''
    }
  }

  const isStableCoin = model.denom.includes("usc")
  const TokenInfoValues: TableCellInfo[] = [
    {
      align: 'center',
      content: (
        <Box paddingLeft={1}>
          <Tooltip title={blockchain ?? 'unknown'} placement="right" arrow>
            <span>
              <BlockchainIcon className={classes.blockChainImg} blockchain={blockchain} />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <TextComponent
            variant="body1"
            svgIcon={undefined}
            showIcon={false}
          // boxClassName={classes.denomText}
          >
            <strong>
              <CellLink to={`/cdp/${encodeURIComponent(model?.denom)}`}>
                {tokenSymbol?.toUpperCase() ?? ''}
              </CellLink>
            </strong>
          </TextComponent>
          <Typography variant="body2">{tokenDesc}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{lendAprBN.toFixed(2) + "%" ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{borrowAprBN.toFixed(2) + "%" ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{totalAmountBN.toNumber() ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{availableBorrow ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{bnOrZero(model?.utilizationRate).shiftedBy(-2).toFixed(2) + "%" ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <CellLink className={classes.denomText} to={`/token/${encodeURIComponent(model?.denom)}`}>
          {model?.denom ?? ''}
        </CellLink>
      )
    },
    {
      align: 'right',
      content: (
        tokenDesc?.includes("IBC") ? "-" : model?.tokenAddress
      ),
      href: (
        tokenDesc?.includes("IBC") ? "" : getAssetIDLink()
      )
    },
    // {
    //   align: 'right',
    //   content: (
    //     <Box paddingRight={1}>
    //       <strong>{model?.decimals.toString()}</strong>
    //     </Box>
    //   ),
    // },
  ]

  const TokenMintInfoValues: TableCellInfo[] = [
    {
      align: 'center',
      content: (
        <Box paddingLeft={1}>
          <Tooltip title={blockchain ?? 'unknown'} placement="right" arrow>
            <span>
              <BlockchainIcon className={classes.blockChainImg} blockchain={blockchain} />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body1">
            <strong>
              {model?.denom?.toUpperCase() ?? ''}
              {/* <CellLink className={classes.denomText} to={`/cdp/${encodeURIComponent(model?.denom)}`}>
              </CellLink> */}
            </strong>
          </Typography>
          <Typography variant="body2">{tokenDesc}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{borrowAprBN.toFixed(2) + "%" ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body2">{totalAmountBN.toNumber() ?? "-"}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <CellLink className={classes.denomText} to={`/token/${encodeURIComponent(model?.denom)}`}>
          {model?.denom ?? ''}
        </CellLink>
      )
    },
    {
      align: 'right',
      content: (
        tokenDesc?.includes("IBC") ? "-" : model?.tokenAddress ?? '-'
      ),
      href: (
        tokenDesc?.includes("IBC") ? "" : getAssetIDLink()
      )
    },
    // {
    //   align: 'right',
    //   content: (
    //     <Box paddingRight={1}>
    //       <strong>{model?.decimals.toString()}</strong>
    //     </Box>
    //   ),
    // },
  ]

  const displayValues = isStableCoin ? TokenMintInfoValues : TokenInfoValues
  return (
    <TableRow key={model?.denom} hover>
      {displayValues.map((info: TableCellInfo, index: number) => (
        <TableSectionCell className={classes.cellClass} {...info} key={index} />
      ))}
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  blockChainImg: {
    marginRight: theme.spacing(1),
    width: '1.125rem',
    height: '1.125rem',
  },
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
  },
  singlelineText: {
    maxWidth: 200,
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  denomText: {
    width: 170,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  }
}))

export default TokenInfoRow
