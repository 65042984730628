import { TableCell, TableRow } from '@material-ui/core'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { CellLink, OrderStatusLabel } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useRedux } from 'js/hooks'
import { SimpleMap, bnOrZero } from 'js/utils'
import React, { ReactElement, useMemo } from 'react'

export interface LiquidationEngineRowProps {
  order: any;
  markets: SimpleMap<Market>
}

const LiquidationEngineRow: React.FunctionComponent<LiquidationEngineRowProps> = ({
  order,
  markets,
}: LiquidationEngineRowProps): ReactElement<LiquidationEngineRowProps> => {
  const {
    market: marketName,
    side,
    status,
    quantity,
    price,
  } = order
  const sdk = useRedux((state) => state.core.carbonSDK)
  const market = markets[marketName]
  const quantityBN = bnOrZero(quantity)
  const priceBN = bnOrZero(price)
  const lotDp = bnOrZero(market?.lotSize).dp() ?? 0
  const tickDp = bnOrZero(market?.tickSize).dp() ?? 0
  const symbolOverride = market?.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
  const baseSymbol = sdk?.token.getTokenName(market?.base ?? '', symbolOverride) ?? ''
  const quantityShifted = sdk?.token.toHuman(market?.base, quantityBN)
  const priceShiftDp = market ? market?.basePrecision?.toNumber() - market?.quotePrecision?.toNumber() : 0
  const priceShifted = priceBN?.shiftedBy(priceShiftDp)
  const positionSide = useMemo(() => side === 'buy' ? 'Short' : 'Long', [side])

  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/order/${order.orderId}`}>{shorten(order.orderId)}</CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/market/${encodeURIComponent(marketName)}`}>{market?.displayName}</CellLink>
      </TableCell>
      <TableCell>{positionSide}</TableCell>
      <TableCell>
        <OrderStatusLabel labelsOverride={{ Cancelled: 'Failed', Filled: 'Success' }}>{status}</OrderStatusLabel>
      </TableCell>
      <TableCell align="right">{quantityShifted?.toFormat(lotDp) ?? '-'} {baseSymbol}</TableCell>
      <TableCell align="right">{priceShifted?.toFormat(tickDp) ?? '-'}</TableCell>
      <TableCell align="right">
        <CellLink to={`/block/${order.blockHeight}`}>{order.blockHeight}</CellLink>
      </TableCell>
    </TableRow>
  )
}

export default LiquidationEngineRow
