import { Coin } from '@cosmjs/stargate';
import { Accordion, AccordionDetails, AccordionSummary, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BigNumber from 'bignumber.js';
import Page from 'js/components/Page';
import { useAsyncTask, useRedux } from 'js/hooks';
import { BN_ZERO } from 'js/utils';
import Long from 'long';
import moment from 'moment';
import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import { bnOrZero } from 'tradehub-api-js/build/main/lib/tradehub/utils';
// import { AccordionDetailsContent } from './components/AccordionDetailsContent';
import { AccordionWithColumnLoading } from './components/AccordionWithColumnLoading';
import { Column } from './components/Column';
import { TokenGroupDetails } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/group';

const AccordionDetailsContent = React.lazy(() => import('./components/AccordionDetailsContent'))

interface Props { }

const GroupList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  // const tokens = useRedux((state) => state.app.tokensMap)
  const [expanded, setExpanded] = useState<any>({})
  const [allGroupsInfo, setAllGroupsInfo] = useState<TokenGroupDetails[] | undefined>(undefined)
  const [last24HourInfo, setLast24HourInfo] = useState<any>(undefined)
  const [totalSupplies, setTotalSupplies] = useState<Coin[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [getAllGroups] = useAsyncTask('getAllGroups')
  // const colors = switcheo.chart // colors Array
  const blacklisted = ['busd.1.2.6eb05f', 'busd.1.6.754a80']

  useEffect(() => {
    if (!sdk) return
    getAllGroups(async () => {
      setLoading(true)
      const allGroupsInfoResp = await sdk?.query.coin.TokenGroupAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      for (let tokenGroup of allGroupsInfoResp.tokenGroups) {
        tokenGroup.registeredTokens = tokenGroup.registeredTokens.filter((o) => !blacklisted.includes(o.denom))
      }
      const allGroups = allGroupsInfoResp.tokenGroups
      setAllGroupsInfo(allGroups)
      const networkURL = sdk.network === 'mainnet' ? '' : sdk.network === 'testnet' ? 'test-' : 'dev-'
      const time24hAgo = moment().utc().subtract(24, 'hours').unix()
      const timeNow = moment().utc().unix()
      const last24HoursRaw = await (await fetch(`https://${networkURL}api-insights.carbon.network/group/balances?interval=hour&from=${time24hAgo}&to=${timeNow}`)).json() // TODO: add to sdk
      setLast24HourInfo(last24HoursRaw?.result)
      const totalSuppliesOfTokens = await sdk.query.bank.TotalSupply({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      setTotalSupplies(totalSuppliesOfTokens?.supply)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const handleChange = (panel: string) => {
    setExpanded((prev: any) => {
      return { ...prev, [panel]: !prev[panel] }
    });
  }

  // prep data to display last 24H infos
  interface GroupBalanceFlow {
    [key: string]: {
      deposits: BigNumber
      withdrawals: BigNumber
      tokenDeposits: {
        [key: string]: BigNumber
      }
      tokenWithdrawals: {
        [key: string]: BigNumber
      }
    }
  }
  const groupBalanceFlow: GroupBalanceFlow = {} //order by group
  for (const l24Info of last24HourInfo?.entries ?? []) {
    const { groups } = l24Info
    for (const group of groups) {
      const { groupId, tokens } = group
      for (const token of tokens) {
        if (blacklisted.includes(token.denom)) continue
        const { depositValue, withdrawalValue, denom } = token
        if (!groupBalanceFlow[groupId]) groupBalanceFlow[groupId] = { deposits: BN_ZERO, withdrawals: BN_ZERO, tokenDeposits: {}, tokenWithdrawals: {} }
        groupBalanceFlow[groupId].deposits = groupBalanceFlow[groupId].deposits.plus(bnOrZero(depositValue))
        groupBalanceFlow[groupId].withdrawals = groupBalanceFlow[groupId].withdrawals.plus(bnOrZero(withdrawalValue).abs())
        if (!groupBalanceFlow[groupId].tokenDeposits[denom]) groupBalanceFlow[groupId].tokenDeposits[denom] = BN_ZERO
        if (!groupBalanceFlow[groupId].tokenWithdrawals[denom]) groupBalanceFlow[groupId].tokenWithdrawals[denom] = BN_ZERO
        groupBalanceFlow[groupId].tokenDeposits[denom] = groupBalanceFlow[groupId].tokenDeposits[denom].plus(bnOrZero(depositValue))
        groupBalanceFlow[groupId].tokenWithdrawals[denom] = groupBalanceFlow[groupId].tokenWithdrawals[denom].plus(bnOrZero(withdrawalValue).abs())
      }
    }
  }
  // console.log(allGroupsInfo)
  // console.log(last24HourInfo)
  // console.log(groupBalanceFlow)

  // Fill in tokens that have 0 balances to groupTokenFlow
  let groupBalanceFlowFilled = { ...groupBalanceFlow }
  for (const group of allGroupsInfo ?? []) {
    const { registeredTokens, tokenGroup } = group
    const groupId = tokenGroup?.id.toString()
    if (!groupId || (groupId && Object.keys(groupBalanceFlowFilled).length && !groupBalanceFlowFilled[groupId])) continue
    for (const registeredToken of registeredTokens) {
      if (blacklisted.includes(registeredToken.denom)) continue
      if (groupBalanceFlowFilled[groupId]?.tokenDeposits[registeredToken.denom] === undefined) {
        if (!groupBalanceFlowFilled[groupId]) groupBalanceFlowFilled[groupId] = { withdrawals: BN_ZERO, deposits: BN_ZERO, tokenDeposits: {}, tokenWithdrawals: {} }
        groupBalanceFlowFilled[groupId].tokenDeposits[registeredToken.denom] = BN_ZERO
        groupBalanceFlowFilled[groupId].tokenWithdrawals[registeredToken.denom] = BN_ZERO
      }
    }
  }
  return (
    <Page title="Grouped Tokens" id="tokenGroupsPage">
      <div style={{ minHeight: '20rem' }}>
        {
          !loading && allGroupsInfo && allGroupsInfo?.map((group) => {
            if (!group?.tokenGroup) return null
            const { id, name, chequeDenom, vaultAddress } = group?.tokenGroup!
            const last24HDeposits = (groupBalanceFlow[id.toString()]?.deposits?.toNumber() ?? 0)
            const last24HWithdrawal = (groupBalanceFlow[id.toString()]?.withdrawals?.toNumber() ?? 0)
            const totalSupplyOfChequeTokenInfo = totalSupplies?.find((o) => o.denom === chequeDenom)
            const chequeDenomDecimals = sdk?.token.getDecimals(chequeDenom)
            const totalSupplyOfChequeToken = bnOrZero(totalSupplyOfChequeTokenInfo?.amount).shiftedBy(-(chequeDenomDecimals ?? 6))
            const chequeTokenUnit = sdk?.token.getSymbol(chequeDenom)
            return (
              <Accordion key={id.toString() + name} id={id.toString() + name} expanded={expanded[id.toString()] ?? false} onChange={(e) => handleChange(id.toString())} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon />}
                  id="panel1bh-header"
                >
                  <div className={classes.columnContainer}>
                    <Column title={"ID"} text={id.toString()} loading={loading} />
                    <Column title={"Group Name"} text={name} loading={loading} />
                    <Column title={"Cheque Denom"} text={chequeDenom} link={`/token/${encodeURIComponent(chequeDenom)}`} loading={loading} />
                    <Column title={"Cheque Token Supply"} text={totalSupplyOfChequeToken.toFixed(chequeDenomDecimals ?? 6)} unit={chequeTokenUnit} loading={loading} />
                    <Column title={"Vault Address"} text={vaultAddress} copy truncate link={`/account/${vaultAddress}`} loading={loading} />
                    <Column title={"Num. Of Tokens"} text={group?.registeredTokens?.length.toString()} loading={loading} />
                    <Column title={"Deposits"} titleUnit={"24H"} text={`${last24HDeposits}`} dollarSign loading={loading} />
                    <Column title={"Withdrawals"} titleUnit={"24H"} text={`${last24HWithdrawal}`} dollarSign loading={loading} />
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <div>
                    <Suspense fallback={<div style={{ width: '100%' }}><LinearProgress /></div>}>
                      <AccordionDetailsContent vaultAddress={vaultAddress} last24HoursData={groupBalanceFlowFilled[id.toString()]} />
                    </Suspense>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })
        }
        { // loading display component
          loading && <div className={classes.loading}>
            <AccordionWithColumnLoading />
          </div>
        }
      </div>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  accordionDetails: {
    backgroundColor: '#E5EFF2', minHeight: '10em', display: 'block',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  loading: {
    // display: 'flex',
    // placeContent: 'center',
    // placeItems: 'center',
    // minHeight: '20rem'
  },
  columnContainer: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 3fr 2fr 3.5fr 2fr 2fr 2fr 2fr',
    width: '100%',
    gap: '1.5em',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0.5em, auto))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      gap: '0.5em'
    }
  },
  accordionSummary: {
    margin: '1rem 0px',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
      '& > .MuiAccordionSummary-content': {
        width: '100%'
      },
    }
  }
}))

export default GroupList
