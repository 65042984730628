import { Box, ListItem, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import dayjs, { Dayjs } from 'dayjs'
import React, { ReactElement } from 'react'

import { CellLink, Section } from 'js/components'
import { TextComponent } from 'js/components/Text'
import { FuturesNameOverride } from 'js/constants'
import { useRedux } from 'js/hooks'
import { bnOrZero } from 'js/utils'


interface Props {
  market?: Market
}

interface ListItemType {
  header: string
  content: number | string | React.ReactNode
}

const MarketInfo: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { market } = props
  const classes = useStyles()
  const sdk = useRedux(state => state.core.carbonSDK)
  const symbolOverride = market?.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
  const baseSymbol = sdk?.token.getTokenName(market?.base ?? '', symbolOverride).toUpperCase() ?? ''
  const quoteSymbol = sdk?.token.getTokenName(market?.quote ?? '', symbolOverride).toUpperCase() ?? ''
  const baseDecimals = market?.basePrecision?.toNumber()
  const expiryDayjs = dayjs(market?.expiryTime)
  let formattedDate: string = expiryDayjs.format('YYYY-MM-DD HH:mm:ss')
  const now: Dayjs = dayjs()
  const diffInYears: number = now.diff(expiryDayjs, 'year')
  if (diffInYears > 10) {
    formattedDate = '-'
  }

  const ListItems: ListItemType[] = [
    {
      header: 'Name',
      content: market?.id,
    },
    {
      header: 'Display Name',
      content: market?.displayName,
    },
    {
      header: 'Description',
      content: market?.description,
    },
    {
      header: 'Type',
      content: market?.marketType,
    },
    {
      header: 'Base Currency',
      content: (
        <Box display="flex" alignItems="center">
          <TextComponent
            svgIcon={undefined}
            showIcon={false}
            variant="body2"
          >
            {baseSymbol}
          </TextComponent>
          <Typography className={classes.subtitle}>
            ({market?.marketType === 'futures' ? (FuturesNameOverride[market?.base] ?? market?.base) : market?.base})
          </Typography>
        </Box>
      ),
    },
    {
      header: 'Base Precision',
      content: market?.basePrecision.toString(),
    },
    {
      header: 'Quote Currency',
      content: (
        <Box display="flex" alignItems="center">
          <TextComponent
            svgIcon={undefined}
            showIcon={false}
            variant="body2"
          >
            {quoteSymbol}
          </TextComponent>
          <Typography className={classes.subtitle}>
            ({market?.marketType === 'futures' ? (FuturesNameOverride[market?.quote] ?? market?.quote) : market?.quote})
          </Typography>
        </Box>
      ),
    },
    {
      header: 'Quote Precision',
      content: market?.quotePrecision.toString(),
    },
    {
      header: 'Lot Size',
      content: market?.lotSize,
    },
    {
      header: 'Tick Size',
      content: market?.tickSize,
    },
    {
      header: 'Min. Quantity',
      content: market?.minQuantity,
    },
    // {
    //   header: 'Maker Fee',
    //   content: percentFormat(market?.makerFee),
    // },
    // {
    //   header: 'Taker Fee',
    //   content: percentFormat(market?.takerFee),
    // },
    {
      header: 'Trading Bandwidth',
      content: percentFormatFromBPS(market?.tradingBandwidth),
    },
    {
      header: 'Risk Step Size',
      content: market?.riskStepSize,
    },
    {
      header: 'Initial Margin Base',
      content: market?.initialMarginBase,
    },
    {
      header: 'Initial Margin Step',
      content: market?.initialMarginStep,
    },
    {
      header: 'Maintenance Margin Ratio',
      content: market?.maintenanceMarginRatio,
    },
    {
      header: 'Index Oracle ID',
      content: !market?.indexOracleId ? '-' : market?.indexOracleId.toString(),
    },
    {
      header: 'Is Active',
      content: booleanFormat(market?.isActive),
    },
    {
      header: 'Is Settled',
      content: booleanFormat(market?.isSettled),
    },
    {
      header: 'Max Liquidation Order Ticket',
      content: bnOrZero(market?.maxLiquidationOrderTicket).shiftedBy(-(baseDecimals ?? 0)).toNumber(),
    },
    {
      header: 'Max Liquidation Order Duration',
      content: `${market?.maxLiquidationOrderDuration?.seconds.toString()}s`,
    },
    {
      header: 'Impact Size',
      content: bnOrZero(market?.impactSize).shiftedBy(-(baseDecimals ?? 0)).toNumber(),
    },
    {
      header: 'Mark Price Band',
      content: percentFormatFromBPS(market?.markPriceBand),
    },
    {
      header: 'Last Price Protected Band',
      content: percentFormatFromBPS(market?.lastPriceProtectedBand),
    },
    {
      header: 'Expiry Time',
      content: formattedDate,
    },
    {
      header: 'Created At',
      content: linkFormat(market?.createdBlockHeight.toString()),
    },
    {
      header: 'Closed At',
      content: linkFormat(market?.closedBlockHeight.toString()),
    },
  ]

  return (
    <Section title="Information">
      {ListItems.map((item) => (
        <ListItem disableGutters key={item?.header.replace(' ', '-')}>
          <div className={classes.header}>
            {item?.header}
          </div>
          <div className={classes.string}>
            {item?.content}
          </div>
        </ListItem>
      ))}
    </Section>
  )
}

// const percentFormat = (value: any) => {
//   if (value) {
//     const valueBN = new BigNumber(value).times(100).toFormat(3)
//     return `${valueBN}%`
//   }
//   return '-'
// }

const percentFormatFromBPS = (value: any) => {
  if (value) {
    const valueBN = new BigNumber(value).dividedBy(100).toFormat(3)
    return `${valueBN}%`
  }
  return '-'
}

const booleanFormat = (value: any) => {
  return value ? 'true' : 'false'
}

const linkFormat = (value: any) => {
  if (value) {
    return (
      <CellLink to={`/block/${value}`}>
        {value}
      </CellLink>
    )
  }
  return '-'
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  subtitle: {
    color: theme.palette.grey[500],
    fontSize: '0.8rem',
    marginLeft: theme.spacing(0.75),
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
}))

export default MarketInfo
