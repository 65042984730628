import BigNumber from 'bignumber.js'
import { Proposal, ProposalStatus, TallyParams, TallyResult } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { Constants } from 'js/constants'
import { useRedux } from 'js/hooks'
import { BIG_ZERO, adjustHuman, bnOrZero } from 'js/utils'
import React from 'react'

interface Props {
  proposal: Proposal
}

enum states {
  WaitMinDeposit,
  WaitQuorum,
  RejectedVeto,
  Rejected,
  Executed,
  RejectedQuorum,
}

const DepositStatus: React.FC<Props> = (props: Props) => {
  const { proposal } = props
  const tallyParams = useRedux((state) => state.governance.tallyParams)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const voteTally = useRedux((state) => state.proposal.liveVoteTally)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const totalStakingPower = Object.values(valAddrMap).reduce((sumShares: BigNumber, valpair) => {
    const delegatorShares = sdk?.token.toHuman('swth', adjustHuman(valpair.carbonValidator.delegatorShares)) ?? BIG_ZERO
    return sumShares = sumShares.plus(delegatorShares)
  }, BIG_ZERO)

  const state = getState(proposal, voteTally, totalStakingPower, tallyParams)
  return (
    <React.Fragment>
      {getMessage(state)}
    </React.Fragment>
  )
}

function getMessage(state: states) {
  switch (state) {
    case states.WaitMinDeposit:
      return 'Waiting for minimum deposit'
    case states.WaitQuorum:
      return 'Waiting for voting period to end'
    case states.Executed: // Proposal Passed
      return 'Deposit was refunded'
    case states.Rejected: // Porposal Rejected
      return 'Deposit was refunded' // 2
    case states.RejectedVeto: // Over 1/3 voted no_with_veto
      return `Deposit was burnt`
    case states.RejectedQuorum: // Failed Quorum
      return `Deposit was burnt`
    default:
      return ''
  }
}

function getState(proposal: Proposal,
  voteTally: TallyResult | undefined,
  totalStakingPower: BigNumber,
  tallyParams: TallyParams | undefined,
) {
  let state = states.WaitMinDeposit
  switch (proposal.status) {
    case ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
      return states.WaitMinDeposit
    case ProposalStatus.PROPOSAL_STATUS_PASSED:
      return states.Executed
    case ProposalStatus.PROPOSAL_STATUS_REJECTED:
      state = states.Rejected
      break;
    case ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
      return states.WaitQuorum
  }
  const resultYes = bnOrZero(voteTally?.yesCount)
  const resultNo = bnOrZero(voteTally?.noCount!)
  const resultVeto = bnOrZero(voteTally?.noWithVetoCount!)
  const resultAbstain = bnOrZero(voteTally?.abstainCount!)
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  const totalStakingPowerUnitless = totalStakingPower.shiftedBy(Constants.Decimals.SWTH)
  // Quorum: more than 40% of the total staked tokens at
  //  the end of the voting period need to have voted
  if ((totalVoted.div(totalStakingPowerUnitless)).lt(new BigNumber(tallyParams?.quorum ?? 0.4))) {
    state = states.RejectedQuorum
    // Veto: More than 33.4% of the tokens that participated in the vote,
    //  not counting “Abstain” votes, have vetoed the decision “No (With Veto)”.
  } else if ((resultVeto.div(totalVoted.minus(resultAbstain))).gt(new BigNumber(tallyParams?.vetoThreshold!) || 0.334)) {
    state = states.RejectedVeto
  }

  return state
}

export default DepositStatus
