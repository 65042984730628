import { Box, Divider, Grid, ListItem, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { DemexLogoSVG } from 'assets'
import { BigNumber } from 'bignumber.js'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import clsx from 'clsx'
import { CellLink, Section, TooltipHint } from 'js/components'
import { capitalizeFirstLetter, snakeToText } from 'js/helpers'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { BIG_ZERO, SHIFT_DECIMALS, adjustHuman, bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'

interface Fees {
  [key: string]: BigNumber
}
interface CollectiveTrades {
  avgPrice: BigNumber,
  fees: Fees,
}

interface Props {
  market: string,
  side: string,
  orderType: string,
  quantity: string,
  price: string,
  filled: string,
  timeInForce: string,
  allocatedMarginDenom: string,
  venue: string,
  stopPrice: string,
  currentPrice: number,
  dollarValue: number,
  demexPrice: string,
  collectiveTrades: CollectiveTrades,
  marketDetails: Market,
  orderStatus: string,
  isFutures: boolean,
  referralAddress: string,
  referralCommission: number,
  referralKickback: number,
  avgTextDisplay: string,
  triggerType: string
}

enum limitMarket {
  stopLimit = "stop-limit",
  stopMarket = "stop-market"
}

const isValidMarketType = (orderType: string) => Object.values(limitMarket).includes(orderType as limitMarket)

const OrderDetails: React.FunctionComponent<Props> = (props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const {
    orderStatus,
    market,
    marketDetails,
    side,
    orderType,
    quantity,
    price,
    dollarValue,
    filled,
    timeInForce,
    allocatedMarginDenom,
    venue,
    stopPrice,
    demexPrice,
    currentPrice,
    collectiveTrades,
    isFutures,
    referralAddress,
    referralCommission,
    referralKickback,
    avgTextDisplay,
    triggerType
  } = props

  let timeInForceDisplay = timeInForce
  switch (timeInForce) {
    case "gtc":
      timeInForceDisplay = "(Good Till Cancel)"
      break
    case "fok":
      timeInForceDisplay = "(Fill or Kill)"
      break
    case "ioc":
      timeInForceDisplay = "(Immediate or Cancel)"
      break
    default:
      timeInForceDisplay = timeInForce
  }

  const demexPriceBN = new BigNumber(demexPrice || currentPrice)
  const tickSizeBN = new BigNumber(marketDetails.tickSize)
  const decimal = tickSizeBN.dp() ?? 0
  const priceShiftDecimals = marketDetails?.basePrecision.sub(marketDetails?.quotePrecision).toNumber()
  const priceFinal = collectiveTrades.avgPrice.gt(0) ? collectiveTrades.avgPrice : bnOrZero(price)
  const priceBN = adjustHuman(priceFinal.toString()).shiftedBy(marketDetails?.basePrecision.sub(marketDetails?.quotePrecision).toNumber())
  const quantityBN = sdk?.token.toHuman(marketDetails?.base, bnOrZero(quantity)) ?? BIG_ZERO
  const filledBN = sdk?.token.toHuman(marketDetails?.base, bnOrZero(filled)) ?? BIG_ZERO

  const amountRecieved = quantityBN.multipliedBy(priceBN).dp(decimal)
  const orderTotalValue = (amountRecieved.times(dollarValue))

  const currentValue = demexPriceBN.times(filledBN)
  const currentDollarValue = (currentValue.times(dollarValue))
  const previousValue = priceBN.times(filledBN)
  const previousDollarValue = (previousValue.times(dollarValue))
  const differenceValue = side === "sell" ? previousDollarValue.minus(currentDollarValue) : currentDollarValue.minus(previousDollarValue)
  const differenceValueBN = new BigNumber(differenceValue.toFixed(2))
  const differencePercentage = side === "sell" ? (currentDollarValue.isZero() ? BN_ZERO : previousDollarValue.times(100).div(currentDollarValue).minus(100)) : (previousDollarValue.isZero() ? BN_ZERO : currentDollarValue.times(100).div(previousDollarValue).minus(100))
  const differencePercentageBN = new BigNumber(differencePercentage.toFixed(2))

  const triggerTypeParsed = snakeToText(triggerType)

  const displayNumbers = !((orderStatus === "cancelled" || demexPriceBN.isZero() || priceBN.isZero()) && filledBN.isZero() && collectiveTrades.avgPrice.isZero())
  // console.log(displayNumbers, collectiveTrades)
  const changeToDollar = (num: BigNumber) => {
    let numString = num.toFixed(2).toLocaleString()
    if (numString[0] === "-") {
      numString = numString.replace("-", "-$")
    }
    else {
      numString = "$" + numString
    }
    return numString
  }
  const symbolOverride = isFutures ? TokenUtils.FuturesDenomOverride : undefined
  // const baseSymbol = sdk?.token.getTokenName(marketDetails?.base ?? '', symbolOverride).toUpperCase() ?? ''
  const quoteSymbol = sdk?.token.getTokenName(marketDetails?.quote ?? '', symbolOverride).toUpperCase() ?? ''
  // const isGroupedToken = marketDetails?.quote === 'cgt/1'

  const getFeeSymbol = (denom: string) => {
    return sdk?.token.getTokenName(denom ?? '', symbolOverride).toUpperCase() ?? ''
  }
  const commissionAmount: (string | BigNumber)[][] = []
  const kickbackAmount = []
  Object.entries(collectiveTrades.fees).map(fee => {
    const [denom, amount] = fee
    const shiftedAmount = sdk?.token.toHuman(denom, amount) ?? bnOrZero(amount)
    commissionAmount.push([getFeeSymbol(denom), shiftedAmount.multipliedBy(bnOrZero(referralCommission)).div(100)])
    kickbackAmount.push([getFeeSymbol(denom), shiftedAmount.multipliedBy(bnOrZero(referralKickback)).div(100)])
    return (
      <div className={classes.string} key={denom + amount.toNumber()}>
        {amount.isZero() && !denom ? "-" : sdk?.token.toHuman(denom, amount).toString()} {(getFeeSymbol(denom))}
      </div>
    )

  })
  const orderPrice = bnOrZero(price).shiftedBy(-SHIFT_DECIMALS + marketDetails?.basePrecision.sub(marketDetails?.quotePrecision).toNumber()).toFixed(decimal)
  return (
    <Section variant="primary" className={classes.section}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant={"h2"} className={classes.title}>
            Potential PNL
            <TooltipHint title="Potential PNL is calculated by current price compared to order price.">
              <InfoIcon className={classes.infoIcon} />
            </TooltipHint>
          </Typography>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <div className={classes.pnlContainer}>
            <Typography variant={"h1"} display={"inline"} className={clsx(classes.pnlH1, { [classes.loss]: differenceValueBN.isLessThan(0), [classes.profit]: differenceValueBN.isGreaterThanOrEqualTo(0) || !displayNumbers })}>
              {displayNumbers && filledBN.isGreaterThan(0) ? changeToDollar(differenceValueBN) : "$0.00"}
            </Typography>
            <Typography variant={"h3"} display={"inline"} className={clsx(classes.pnlH3, { [classes.loss]: differenceValueBN.isLessThan(0), [classes.profit]: differenceValueBN.isGreaterThanOrEqualTo(0) || !displayNumbers })}>
              {differencePercentageBN.isNaN() || !displayNumbers || filledBN.isZero() ? "0.00" : differencePercentageBN.toFixed(2)}%
            </Typography>
          </div>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          Current Value: {displayNumbers ? changeToDollar(currentDollarValue) : "-"}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Market</div>
            <div className={classes.string}>
              <CellLink to={`/market/${encodeURIComponent(market)}`}>{market.toUpperCase()}</CellLink>
            </div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Side</div>
            <div className={classes.string}>{capitalizeFirstLetter(side)}</div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Type</div>
            <div className={classes.string}>{capitalizeFirstLetter(orderType)} {timeInForceDisplay}</div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Filled / Quantity</div>
            <div className={classes.string}>{filledBN.toString()} / {quantityBN.toString()}</div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Target Price</div>
            {
              orderPrice ? (
                <>
                  <div className={classes.string}>{orderType === "market" ? 'Market' : orderPrice}</div>
                </>
              )
                : "-"
            }
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>{avgTextDisplay}</div>
            {
              displayNumbers ? (
                <>
                  <div className={classes.string}>{priceBN.toFixed(decimal)} (Current Price: {demexPriceBN.toFixed(decimal)})</div>
                </>
              )
                : "-"
            }
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Order Value</div>
            <div className={classes.string}>
              <Typography className={classes.longString}>
                <Box fontWeight={displayNumbers && filledBN.isGreaterThan(0) ? 700 : 0} component={"span"}>
                  {
                    displayNumbers && filledBN.isGreaterThan(0) ? (
                      <>
                        {
                          (
                            <>{amountRecieved.toString(10)} {quoteSymbol} ({changeToDollar(orderTotalValue)})</>
                          )
                        }
                      </>
                    )
                      : "-"
                  }
                </Box>
              </Typography>
            </div>
            <div className={classes.header}>Filled Value</div>
            <div className={classes.string}>
              <Typography className={classes.longString}>
                <Box fontWeight={displayNumbers && filledBN.isGreaterThan(0) ? 700 : 0} component={"span"}>
                  {
                    displayNumbers && filledBN.isGreaterThan(0) ? (
                      <>
                        {
                          (
                            <>{previousValue.toString(10)} {quoteSymbol} ({changeToDollar(previousDollarValue)})</>
                          )
                        }
                      </>
                    )
                      : "-"
                  }
                </Box>
              </Typography>
            </div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Fee Paid</div>
            {
              displayNumbers ? (
                <div>
                  {
                    Object.entries(collectiveTrades.fees).map(fee => {
                      const [denom, amount] = fee
                      return (
                        <div className={classes.string} key={denom + amount.toNumber()}>
                          {amount.isZero() && !denom ? "-" : sdk?.token.toHuman(denom, amount).toString()} {(getFeeSymbol(denom))}
                        </div>
                      )

                    })
                  }
                </div>
              )
                : "-"
            }
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Referrer</div>
            <div className={classes.longStringAddress}>
              <CellLink to={`/account/${referralAddress}`}>{referralAddress || '-'}</CellLink>
            </div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Commission / Kickback</div>
            <div className={classes.longStringAddress}>
              <div>{`${referralCommission || '-'}`}{!!referralCommission && '%'}</div>
              {/* <div>{`${referralCommission}% (${commissionAmount.map(([denom, amount]) => `${amount} ${denom}`)})`}</div> */}
              <div>{referralKickback || '-'}{!!referralKickback && '%'}</div>
            </div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          {isValidMarketType(orderType) &&
            (
              <>
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Trigger At</div>
                  <div className={classes.string}>{triggerTypeParsed} ≤ {bnOrZero(stopPrice).shiftedBy(priceShiftDecimals).shiftedBy(-SHIFT_DECIMALS).toString(10)} {sdk?.token.getTokenName(allocatedMarginDenom)} </div>
                </ListItem>
                <Divider
                  className={classes.divider}
                  variant="middle"
                />
              </>
            )
          }
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Trade Venue</div>
            <div className={classes.string}>
              <CellLink href="https://app.dem.exchange">
                <SvgIcon
                  component={DemexLogoSVG}
                  className={classes.demexIcon}
                />
              </CellLink>
              <Typography display="inline">
                <Box fontWeight={700} display="inline" component={"span"}>
                  {venue}
                </Box>
              </Typography>
            </div>
          </ListItem>
          {/* hide: implementation for sharing not done */}
          {/* <Box display={"flex"} justifyContent={"flex-end"} className={classes.buttonBox}>
                <Button
                  color="secondary"
                  className={classes.copyButton}
                  startIcon={<CopyTradeSVG fontSize={'small'} className={classes.buttonIcon}/>}
                >
                  Copy Trade
                </Button>
                <Button
                  color="secondary"
                  className={classes.counterButton}
                  startIcon={<CounterTradeSVG fontSize={'small'} className={classes.buttonIcon}/>}
                >
                  Counter Trade
                </Button>
              </Box> */}
        </Grid>
      </Grid>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    alignSelf: 'center',
    width: '100%',
    margin: theme.spacing(0.75, 0),
  },
  section: {
    height: '100%'
  },
  buttonBox: {
    margin: theme.spacing(2.5, 0, 0, 0)
  },
  string: {
    wordBreak: 'break-word',
  },
  header: {
    // flexBasis: '33%',
    paddingRight: '1rem',
  },
  infoIcon: {
    verticalAlign: 'text-top',
    margin: theme.spacing(0, 1.5)
  },
  pnlContainer: {
    wordBreak: "break-word"
  },
  pnlH1: {
    margin: theme.spacing(0, 1.2, 0, 0)
  },
  pnlH3: {
    whiteSpace: "pre"
  },
  loss: {
    color: switcheo.loss,
  },
  profit: {
    color: switcheo.green[400],
  },
  title: {
    marginBottom: theme.spacing(2.5)
  },
  copyButton: {
    borderRadius: 0,
    backgroundColor: switcheo.copyTrade,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.8, 1.25),
    fontSize: 'x-small',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: switcheo.default.white,
    wordBreak: 'normal',
    "&:hover": {
      backgroundColor: switcheo.copyTradeHover
    }
  },
  counterButton: {
    borderRadius: 0,
    backgroundColor: switcheo.counterTrade,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.8, 1.25),
    fontSize: 'x-small',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: 'white',
    wordBreak: 'normal',
    "&:hover": {
      backgroundColor: switcheo.counterTradeHover
    }
  },
  demexIcon: {
    margin: theme.spacing(0, 0.5, 0, 0),
    fontSize: '1rem',
    verticalAlign: 'text-bottom'
  },
  buttonIcon: {
    margin: theme.spacing(0, -0.3, 0, 0.5),
    fontSize: '1rem !important'
  },
  longString: {
    wordBreak: "break-word"
  },
  longStringAddress: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 1,
    overflow: 'hidden',
    display: 'inline-block'
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '33% auto'
  },
  boldText: {
    fontWeight: 700,
    whiteSpace: 'nowrap'
  },
  groupedTokenContainer: {
    display: 'flex',
    gap: '0.3em',
  }
}))

export default OrderDetails
