import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import { ReactComponent as LinkIcon } from 'assets/HorizontalArrow.svg'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { CellLink, TooltipHint } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { bnOrZero, returnUrlNet, scrollToView, SimpleMap } from 'js/utils'
import React, { ReactElement, useMemo } from 'react'
import { HashLink } from 'react-router-hash-link'

export interface LiquidationADLRowProps {
  order: any;
  markets: SimpleMap<Market>
}

const LiquidationADLRow: React.FunctionComponent<LiquidationADLRowProps> = ({
  order,
  markets,
}: LiquidationADLRowProps): ReactElement<LiquidationADLRowProps> => {
  const {
    address,
    market: marketName,
    initiator,
    side,
    quantity,
    price,
  } = order
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const network = useRedux(state => returnUrlNet(state.app.network))
  const market = markets[marketName]
  const quantityBN = bnOrZero(quantity)
  const priceBN = bnOrZero(price)
  const lotDp = bnOrZero(market?.lotSize).dp() ?? 0
  const tickDp = bnOrZero(market?.tickSize).dp() ?? 0
  const symbolOverride = market?.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
  const baseSymbol = sdk?.token.getTokenName(market?.base ?? '', symbolOverride) ?? ''
  const positionSide = useMemo(() => side === 'buy' ? 'Short' : 'Long', [side])
  const quantityShifted = sdk?.token.toHuman(market?.base, quantityBN)
  const priceShifted = priceBN?.shiftedBy(bnOrZero(market?.basePrecision?.toNumber()).toNumber() - bnOrZero(market?.quotePrecision?.toNumber()).toNumber())

  const eventName = useMemo(() => {
    switch (initiator) {
      case 'system_liquidate': return `Liquidate ${positionSide} Position`
      case 'system_adl': return `ADL ${positionSide} Position`
      case 'system_derisk': return `Derisk ${positionSide} Position`
      default: return `${initiator.replace(/^system_/i, '')} ${positionSide} Position`
    }
  }, [initiator, positionSide])

  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/order/${order.orderId}`}>{shorten(order.orderId)}</CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/account/${address}`}>
          {shorten(address)}
        </CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/market/${encodeURIComponent(marketName)}`}>{market?.displayName}</CellLink>
      </TableCell>
      <TableCell>
        {initiator}
      </TableCell>
      <TableCell>
        {eventName}
        <TooltipHint title="See account positions" placement="left">
          <HashLink
            scroll={(el) => scrollToView(el)}
            to={`/account/${address}?net=${network}#position-history`}
            style={{ color: switcheo.primary[500] }}
          >
            <LinkIcon className={classes.icon} />
          </HashLink>
        </TooltipHint>
      </TableCell>
      <TableCell align="right">{quantityShifted?.toFormat(lotDp)} {baseSymbol}</TableCell>
      <TableCell align="right">{priceShifted?.toFormat(tickDp)}</TableCell>
      <TableCell align="right">
        <CellLink to={`/block/${order.blockHeight}`}>{order.blockHeight}</CellLink>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    maxWidth: theme.spacing(2),
    height: theme.spacing(2),
    verticalAlign: "bottom",
    opacity: 0.5,
    transition: "transform .05s ease-in-out",
    transform: "rotate(0deg)",
    "&:hover, &:focus": {
      transform: "rotate(-45deg)",
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(0)",
    },
  },
}))

export default LiquidationADLRow
