import BigNumber from "bignumber.js"
import { CarbonSDK } from "carbon-js-sdk"
import { Params } from "carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/params"
import { RateStrategyParams } from "carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/rate_strategy_params"
import { TokenBalance } from "carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token"
import { BN_ONE, BN_ZERO, SHIFT_DECIMALS, bnOrZero } from "js/utils"
import { UserCDPBalance } from "../Account/Account"

export interface Assets {
  denom: string,
  oracleId: string,
  rateStrategyName: string,
  repayStablecoinInterest: boolean,
  loanToValue: number,
  liquidationThreshold: number,
  liquidationDiscount: number,
  supplyCap: number,
  borrowCap: number
  totalBorrowed: number,
  totalAmount: number,
  utilizationRate: number,
  tokenAddress: string,
  decimals: number,
  lendApr: number | string,
  borrowApr: number | string,
  availableBorrow: number,
  collaterals: number | string,
  collateralToActualUSD: number,
  collateralToActual: number,
  symbol: string,
}


export function parseRateStrategy(rateStrategy: RateStrategyParams | undefined) {
  if (!rateStrategy) return {
    optimalUsage: BN_ZERO,
    baseVariableBorrowRate: BN_ZERO,
    variableRateSlope1: BN_ZERO,
    variableRateSlope2: BN_ZERO,
  }

  return {
    ...rateStrategy, // name
    optimalUsage: new BigNumber(rateStrategy.optimalUsage),
    baseVariableBorrowRate: new BigNumber(rateStrategy.baseVariableBorrowRate),
    variableRateSlope1: new BigNumber(rateStrategy.variableRateSlope1),
    variableRateSlope2: new BigNumber(rateStrategy.variableRateSlope2),
  }
}
export const getInterestRate = (asset: Assets, params: Params, rateStrategies: RateStrategyParams[]) => {
  let borrowApr = BN_ZERO
  let lendApr = BN_ZERO
  const rateStrategy = rateStrategies.find((r) => r.name === asset.rateStrategyName)
  if (!rateStrategy) return { ...asset, lendApr: lendApr.shiftedBy(-SHIFT_DECIMALS).toNumber(), borrowApr: borrowApr.shiftedBy(-SHIFT_DECIMALS).toNumber() }

  const { utilizationRate } = asset
  const uRate = new BigNumber(utilizationRate).shiftedBy(0) //-4 to convert to bps
  const { optimalUsage, baseVariableBorrowRate, variableRateSlope1, variableRateSlope2 } = parseRateStrategy(rateStrategy)

  if (uRate.lt(optimalUsage)) {
    borrowApr = baseVariableBorrowRate.plus((uRate.dividedBy(optimalUsage)).times(variableRateSlope1))
  } else {
    borrowApr = baseVariableBorrowRate.plus(variableRateSlope1).plus(((uRate.minus(optimalUsage)).dividedBy(BN_ONE.minus(optimalUsage))).times(variableRateSlope2))
  }

  const interestFeePercentage = new BigNumber(params.interestFee).dividedBy(10000)
  lendApr = borrowApr.times(BN_ONE.minus(interestFeePercentage))
  return { ...asset, lendApr: lendApr.shiftedBy(-2).toFixed(2), borrowApr: borrowApr.shiftedBy(-2).toFixed(2) }
}

export const getInterestRateReturn = (asset: Assets, params: Params, rateStrategies: RateStrategyParams[]) => {
  let borrowApr = BN_ZERO
  let lendApr = BN_ZERO
  const rateStrategy = rateStrategies.find((r) => r.name === asset.rateStrategyName)
  if (!rateStrategy) return { lendApr: lendApr.shiftedBy(-SHIFT_DECIMALS).toNumber(), borrowApr: borrowApr.shiftedBy(-SHIFT_DECIMALS).toNumber() }

  const { utilizationRate } = asset
  const uRate = new BigNumber(utilizationRate).shiftedBy(2) //-4 to convert to bps
  const { optimalUsage, baseVariableBorrowRate, variableRateSlope1, variableRateSlope2 } = parseRateStrategy(rateStrategy)

  if (uRate.lt(optimalUsage)) {
    borrowApr = baseVariableBorrowRate.plus((uRate.dividedBy(optimalUsage)).times(variableRateSlope1))
  } else {
    borrowApr = baseVariableBorrowRate.plus(variableRateSlope1).plus(((uRate.minus(optimalUsage)).dividedBy(bnOrZero(10000).minus(optimalUsage))).times(variableRateSlope2))
  }
  const interestFeePercentage = new BigNumber(params.interestFee).dividedBy(10000)
  lendApr = borrowApr.times(BN_ONE.minus(interestFeePercentage))

  return { lendApr: lendApr.toFixed(2), borrowApr: borrowApr.toFixed(2) }
}

export interface Items {
  tooltip?: string,
  field: string,
  value: string | number,
  bold?: boolean
}

export interface InfoCards {
  title: string,
  items: Items[],
  title2?: string,
  items2?: Items[]
}

export const getUserCDPBalance = async (allCIBTTokens: TokenBalance[], address: string, sdk: CarbonSDK) => {
  const userCDPBalance: UserCDPBalance[] = []
  const userCollateralBalance = await sdk?.query.cdp.AccountCollateralAll({ address: address })
  const isInBalance: any = {}
  for (const allCIBTToken of allCIBTTokens) {
    const collateralized = userCollateralBalance?.collaterals.find((o) => o.denom === allCIBTToken.denom)
    if (collateralized) isInBalance[collateralized?.cibtDenom] = true
    const ratio = await sdk?.cdp.getCdpToActualRatio(allCIBTToken.denom)
    let decimals = sdk.token.getDecimals(allCIBTToken.denom)
    decimals = allCIBTToken.denom === "cibt/ibc/BAA1D21893B1D36865C6CA44D18F4ACF08BAD70CB6863C4722E0A61703808F77" ? 18 : decimals
    const underlying = bnOrZero(allCIBTToken.available).dividedBy(ratio ?? 0).shiftedBy(-(decimals ?? 6))
    userCDPBalance.push({
      ...allCIBTToken,
      underlying,
      underlyingDenom: collateralized?.denom ?? allCIBTToken.denom.replace("cibt/", ""),
      collateralized: bnOrZero(collateralized?.collateralAmount).dividedBy(ratio).shiftedBy(-(decimals ?? 6))
    })
  }
  // Check for fully collateralized cibt token (not in user balance)
  for (const collateralToken of userCollateralBalance?.collaterals) {
    if (!isInBalance[collateralToken?.cibtDenom]) {
      const ratio = await sdk?.cdp.getCdpToActualRatio(collateralToken?.cibtDenom)
      let decimals = sdk.token.getDecimals(collateralToken?.cibtDenom)
      decimals = collateralToken?.cibtDenom === "cibt/ibc/BAA1D21893B1D36865C6CA44D18F4ACF08BAD70CB6863C4722E0A61703808F77" ? 18 : decimals
      const underlying = BN_ZERO
      userCDPBalance.push({
        denom: collateralToken.cibtDenom,
        available: "0",
        order: "0",
        position: "0",
        underlying,
        underlyingDenom: collateralToken?.denom,
        collateralized: bnOrZero(collateralToken?.collateralAmount).dividedBy(ratio).shiftedBy(-(decimals ?? 6))
      })
    }
  }
  return userCDPBalance
}